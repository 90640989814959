<template>
    <div
        v-if="attachments.length"
        class="flex flex-col h-full"
    >
        <div class="flex justify-between font-frank text-sm text-black pb-5 mb-6 border-b">
            <div class="font-bold">
                {{ activeItem.filename }}
            </div>
            <div class="font-normal">
                {{ activeIndex + 1 }} of {{ attachments.length }}
            </div>
        </div>

        <div class="flex items-center justify-center flex-grow relative overflow-auto mb-4">
            <div
                v-if="attachments.length > 1"
                class="flex items-center justify-center px-12"
            >
                <button
                    class="prev"
                    @click="showPrevious"
                >
                    <ChevronLeft2Icon class="w-full h-full" />
                </button>
            </div>

            <div class="flex-grow h-full flex items-center justify-center">
                <img
                    v-if="activeItem.isImage && activeItem.objectUrl"
                    class="max-h-full max-w-full object-contain"
                    :src="activeItem.objectUrl"
                    alt=""
                >
                <iframe
                    v-else-if="activeItem.contentType === 'application/pdf' && activeItem.objectUrl"
                    :src="activeItem.objectUrl"
                    width="100%"
                    height="100%"
                    type="application/pdf"
                />
                <div
                    v-else
                    class="flex flex-col items-center justify-center"
                >
                    <FileTextIcon class="w-20 h-20 mb-4" />
                    <a
                        v-if="activeItem.objectUrl"
                        :href="activeItem.objectUrl"
                        :download="activeItem.filename"
                        class="text-link"
                    >download</a>
                </div>
            </div>

            <div
                v-if="attachments.length > 1"
                class="flex items-center justify-center px-12"
            >
                <button
                    class="next"
                    @click="showNext"
                >
                    <ChevronLeft2Icon class="w-full h-full transform rotate-180" />
                </button>
            </div>
        </div>

        <div
            v-if="attachments.length > 1"
            class="thumbnails"
        >
            <button
                class="prev"
                @click="showPrevious"
            >
                <ChevronLeft2Icon class="w-4 h-8" />
            </button>
            <div
                ref="thumbnailsScrollContainer"
                class="scroll-container"
            >
                <div
                    v-for="(item, index) of attachments"
                    :key="item.id"
                    class="thumbnail"
                    :class="{ active: activeIndex === index }"
                    @click="activeIndex = index"
                >
                    <img
                        v-if="item.isImage && item.previewUrl"
                        class="w-full h-full object-cover"
                        :src="item.previewUrl"
                        alt=""
                    >
                    <FileTextIcon
                        v-else
                        class="w-10 h-10"
                    />
                </div>
            </div>
            <button
                class="next"
                @click="showNext"
            >
                <ChevronLeft2Icon class="w-4 h-8 transform rotate-180" />
            </button>
        </div>
        <loader
            :loading="loading"
            backdrop
        />
    </div>
</template>

<script>
import ModalNavigation from '@/mixins/ModalNavigation';
import ChevronLeft2Icon from '@/components/ui/icons/ChevronLeft2Icon';
import FileTextIcon from '@/components/ui/icons/FileTextIcon';
import NotifyMixin from '@/mixins/NotifyMixin';
import { mapActions, mapGetters } from 'vuex';
import EventBus from '@/utils/EventBus';
import Axios from 'axios';
import { isUrlImage } from '@/utils/files';
import Loader from '@/components/ui/Loader';

export default {
    components: { Loader, FileTextIcon, ChevronLeft2Icon },

    mixins: [ModalNavigation, NotifyMixin],

    data() {
        return {
            activeIndex: 0,
            loading: false,
        };
    },

    computed: {
        ...mapGetters({
            attachments: 'attachments/attachments',
        }),

        activeItem() {
            return this.attachments[this.activeIndex];
        },
    },

    watch: {
        activeIndex(index) {
            this.$refs.thumbnailsScrollContainer.children[index].scrollIntoView({ behavior: 'smooth', inline: 'center' });
        },

        activeItem: {
            handler: async function () {
                this.updateActions();

                if (!this.activeItem) {
                    return;
                }

                if (!this.activeItem.objectUrl) {
                    try {
                        this.loading = true;

                        const items = await this.$attachmentsDataProvider.getList('attachments', {
                            ids: [this.activeItem.id],
                            preview: false,
                        });
                        const { data } = await Axios.get(items[0].url, { responseType: 'blob' });
                        const objectUrl = URL.createObjectURL(data);
                        const isImage = await isUrlImage(objectUrl);
                        this.updateAttachment({
                            id: this.activeItem.id,
                            values: {
                                objectUrl,
                                isImage,
                            },
                        });
                    } catch (error) {
                        this.notifyError('Failed to load attachment');
                    } finally {
                        this.loading = false;
                    }
                }
            },

            immediate: true,
        },

        attachments(items) {
            this.updateActions();

            if (items.length === 0) {
                this.close();
            }
        },
    },

    mounted() {
        if (this.attachments.length === 0) {
            this.close();
        }

        EventBus.on('confirm-atts-delete-attachment', this.handleDeleteCurrentAttachment);
        EventBus.on('confirm-atts-delete-all-attachments', this.handleDeleteAllAttachments);

        const { openOn } = this.$route.query;
        if (openOn) {
            const index = this.attachments.findIndex(({ id }) => id === openOn);
            this.activeIndex = index >= 0 ? index : 0;
        }

        this.updateActions();
    },

    beforeUnmount() {
        EventBus.off('confirm-atts-delete-all-attachments', this.handleDeleteAllAttachments);
        EventBus.off('confirm-atts-delete-attachment', this.handleDeleteCurrentAttachment);
    },

    methods: {
        ...mapActions({
            addAction: 'actions/addAction',
            removeAction: 'actions/removeAction',
            removeAttachment: 'attachments/removeAttachment',
            clearAttachments: 'attachments/clearAttachments',
            updateAttachment: 'attachments/updateAttachment',
        }),

        updateActions() {
            if (!this.activeItem?.stored) {
                this.addAction({
                    routeName: this.$route.name,
                    item: {
                        id: 'atts-delete-attachment',
                        title: 'delete current attachment',
                        showConfirmation: true,
                        confirmationType: 'warning',
                        confirmationMessage: 'are you sure you want to delete the attachment? It can’t be restored.',
                        confirmBtnText: 'delete',
                        cancelBtnText: 'no, go back',
                    },
                });
            } else {
                this.removeAction({
                    routeName: this.$route.name,
                    item: {
                        id: 'atts-delete-attachment',
                    },
                });
            }

            if (this.attachments.some(item => !item.stored)) {
                this.addAction({
                    routeName: this.$route.name,
                    item: {
                        id: 'atts-delete-all-attachments',
                        title: 'delete all non-uploaded attachments',
                        showConfirmation: true,
                        confirmationType: 'warning',
                        confirmationMessage: 'are you sure you want to delete all non-uploaded attachments? This action can’t be undone.',
                        confirmBtnText: 'delete all',
                        cancelBtnText: 'no, go back',
                    },
                });
            } else {
                this.removeAction({
                    routeName: this.$route.name,
                    item: {
                        id: 'atts-delete-all-attachments',
                    },
                });
            }
        },

        showPrevious() {
            this.activeIndex = this.activeIndex === 0 ? this.attachments.length - 1 : this.activeIndex - 1;
        },

        showNext() {
            this.activeIndex = this.activeIndex === this.attachments.length - 1 ? 0 : this.activeIndex + 1;
        },

        handleDeleteCurrentAttachment() {
            const { id } = this.activeItem;
            this.showNext();
            this.removeAttachment(id);
        },

        handleDeleteAllAttachments() {
            this.clearAttachments();
        },
    },
};
</script>

<style scoped>
.prev,
.next {
    @apply w-4 h-8 text-graphite-800 outline-none px-4 py-5;
    border-radius: 1px;
    width: 3rem;
    height: 4.5rem;

    &:hover {
        @apply text-active-500 bg-active-25;
    }
}

.thumbnails {
    @apply flex items-center justify-center relative;

    .prev,
    .next {
        @apply absolute w-16 h-full hidden;

        &:hover {
            @apply text-blue-500;
        }
    }

    .prev {
        @apply left-0;
        background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    }

    .next {
        @apply right-0;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    }

    &:hover {
        .prev,
        .next {
            @apply flex items-center justify-center;
        }
    }
}

.scroll-container {
    @apply flex max-w-full overflow-auto flex-shrink-0;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
}

.thumbnail {
    @apply flex items-center justify-center cursor-pointer m-2 overflow-hidden;
    border-radius: 1px;
    flex: none;
    width: 72px;
    height: 72px;

    &.active {
        @apply border-2 border-blue-500;
    }
}
</style>
